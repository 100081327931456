import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-montgomery-alabama.png'
import image0 from "../../images/cities/scale-model-of-escapology-montgomery-in-montgomery-alabama.png"
import image1 from "../../images/cities/scale-model-of-civil-rights-memorial-center-in-montgomery-alabama.png"
import image2 from "../../images/cities/scale-model-of-first-white-house-of-the-confederacy-in-montgomery-alabama.png"
import image3 from "../../images/cities/scale-model-of-the-hank-williams-museum-in-montgomery-alabama.png"
import image4 from "../../images/cities/scale-model-of-saint-john's-episcopal-church-in-montgomery-alabama.png"
import image5 from "../../images/cities/scale-model-of-dexter-avenue-king-memorial-baptist-church-in-montgomery-alabama.png"
import image6 from "../../images/cities/scale-model-of-rosa-parks-museum-in-montgomery-alabama.png"
import image7 from "../../images/cities/scale-model-of-the-legacy-museum-in-montgomery-alabama.png"
import image8 from "../../images/cities/scale-model-of-museum-of-alabama-in-montgomery-alabama.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Montgomery'
            state='Alabama'
            image={image}
            lat='32.3792233'
            lon='-86.30773679999999'
            attractions={ [{"name": "Escapology Montgomery", "vicinity": "130 Commerce St Suite 700, Montgomery", "types": ["point_of_interest", "establishment"], "lat": 32.3797721, "lng": -86.31119810000001}, {"name": "Civil Rights Memorial Center", "vicinity": "400 Washington Ave, Montgomery", "types": ["museum", "point_of_interest", "establishment"], "lat": 32.3761544, "lng": -86.30334749999997}, {"name": "First White House of the Confederacy", "vicinity": "644 Washington Ave, Montgomery", "types": ["museum", "point_of_interest", "establishment"], "lat": 32.3761391, "lng": -86.2999284}, {"name": "The Hank Williams Museum", "vicinity": "118 Commerce St, Montgomery", "types": ["museum", "point_of_interest", "establishment"], "lat": 32.379447, "lng": -86.31068399999998}, {"name": "Saint John's Episcopal Church", "vicinity": "113 Madison Ave, Montgomery", "types": ["church", "place_of_worship", "point_of_interest", "establishment"], "lat": 32.3802087, "lng": -86.3073286}, {"name": "Dexter Avenue King Memorial Baptist Church", "vicinity": "454 Dexter Ave, Montgomery", "types": ["church", "place_of_worship", "point_of_interest", "establishment"], "lat": 32.377297, "lng": -86.30270889999997}, {"name": "Rosa Parks Museum", "vicinity": "252 Montgomery St, Montgomery", "types": ["museum", "point_of_interest", "establishment"], "lat": 32.3768141, "lng": -86.3111705}, {"name": "The Legacy Museum", "vicinity": "115 Coosa St, Montgomery", "types": ["museum", "point_of_interest", "establishment"], "lat": 32.3799526, "lng": -86.31019809999998}, {"name": "Museum of Alabama", "vicinity": "142-158 S Union St, Montgomery", "types": ["museum", "point_of_interest", "establishment"], "lat": 32.3757531, "lng": -86.29997149999997}] }
            attractionImages={ {"Escapology Montgomery":image0,"Civil Rights Memorial Center":image1,"First White House of the Confederacy":image2,"The Hank Williams Museum":image3,"Saint John's Episcopal Church":image4,"Dexter Avenue King Memorial Baptist Church":image5,"Rosa Parks Museum":image6,"The Legacy Museum":image7,"Museum of Alabama":image8,} }
       />);
  }
}